import React from "react";
import Content from '../views/Fleet/Why';

export default ({location}) => (
  <Content location={location} seo={{
    title: "How can a business benefit from a fleet management system?",
    lang: 'en',
    description: "Discover how a fleet management system can empower your business and optimize your fleet & increase productivity of your vehicles.",
    meta: [{
        name: 'keywords',
        content: 'Fleet management system'
      }]
  }}/>
);